


























import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
@Component({})
export default class AUser extends Vue {
  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  btnloading = false
  // 总条数
  total = 0;

  isDialog = false;
  list: any = [];

  // 搜索条件
  search = {
    keyword: '',
    dataTime: [],
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };

  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post("J30120", this.search)
      .then((data) => {
        this.total = data.total;
        this.tableData = data.list;

      })
      .finally(() => {
        this.loading = false;
      });
  }

  // 交易中心
  channel = []
  getChannelDict() {
    api
      .post('A13005')
      .then(res => {
        this.channel = res
      })
  }

  activated() {
    this.getChannelDict()
  }

}
